












































































































































































/* eslint-disable @typescript-eslint/camelcase */
import { Vue, Component, Watch } from 'vue-class-decorator'
import { GlobalModule, SchedulesModule } from '@/store'
import { Payload } from 'vue/interfaces'

@Component({
  metaInfo: {
    title: 'Отдел кадров'
  },
  components: {
    SchedulesTable: () => import('@/components/pages/SchedulesTable.vue'),
    Lateness: () => import('@/components/pages/Lateness.vue'),
    DaysOff: () => import('@/components/pages/DaysOff.vue')
  }
})
export default class Schedules extends Vue {
  public scheduleDialog: boolean
  public scheduleDialogTitle: string
  public scheduleDialogAcceptTitle: string
  public scheduleDialogData: any

  constructor() {
    super()
    this.scheduleDialogTitle = 'Создание графика'
    this.scheduleDialogAcceptTitle = 'Создать'
    this.scheduleDialog = false
    this.scheduleDialogData = {
      user_id: null,
      group_id: null,
      schedule: {
        1: ['10:00', '20:00'],
        2: ['10:00', '20:00'],
        3: ['10:00', '20:00'],
        4: ['10:00', '20:00'],
        5: ['10:00', '20:00']
      }
    }
  }

  @GlobalModule.Getter('getLoading') loading!: boolean

  @SchedulesModule.Action('requestPageData') loadPageData!: () => Promise<void>
  @SchedulesModule.Action('saveSchedule') pushSchedule!: (schedule: Payload) => Promise<void>
  @SchedulesModule.Action('requestDelete') deleteSchedule!: (id: number) => Promise<void>
  @SchedulesModule.Getter('getUsers') users!: Array<object>
  @SchedulesModule.Getter('getUserGroups') userGroups!: Array<object>
  @SchedulesModule.Getter('getSchedules') schedules!: Array<any>

  @Watch('scheduleDialogData.user_id') unselectGroup() {
    if (this.scheduleDialogData.user_id) {
      this.scheduleDialogData.group_id = null
    }
  }

  @Watch('scheduleDialogData.group_id') unselectUser() {
    if (this.scheduleDialogData.group_id) {
      this.scheduleDialogData.user_id = null
    }
  }

  deleteProcess(id: number) {
    this.deleteSchedule(id)
      .then(() => {
        this.$noty('success', 'График успешно удалён!', 7000).show()
        this.loadPageData()
      })
      .catch((error) => {
        this.$noty('error', error.message, 7000).show()
      })
  }

  saveSchedule() {
    this.pushSchedule(this.scheduleDialogData as Payload)
      .then(() => {
        this.$noty('success', 'График успешно сохранён!', 5000).show()
        this.scheduleDialog = false
        this.loadPageData()
      })
      .catch((error: any) => {
        this.$noty('error', error.message, 7000).show()
      })
  }

  createScheduleDialog() {
    this.scheduleDialogTitle = 'Создание графика'
    this.scheduleDialogAcceptTitle = 'Создать'
    this.scheduleDialogData = {
      user_id: null,
      group_id: null,
      schedule: {
        1: ['10:00', '20:00'],
        2: ['10:00', '20:00'],
        3: ['10:00', '20:00'],
        4: ['10:00', '20:00'],
        5: ['10:00', '20:00']
      }
    }

    this.scheduleDialog = true
  }

  editSchedule(id: number) {
    const schedule = this.schedules.filter((schedule) => schedule.schedule_id === id)

    this.scheduleDialogTitle = 'Редактирование графика'
    this.scheduleDialogAcceptTitle = 'Сохранить'
    this.scheduleDialogData = {
      schedule_id: schedule[0].schedule_id,
      user_id: schedule[0].user_id,
      group_id: schedule[0].group_id,
      schedule: schedule[0].schedule
    }

    this.scheduleDialog = true
  }

  created() {
    this.loadPageData()
  }
}
